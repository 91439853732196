.mpStyle {
  width: 1000;
  height: 450;
  margin-top: 35px;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .mpStyle {
    height: 400px;
    max-width: 100%;
  }
}
